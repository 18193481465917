<template>
    <v-form
        class="a-contact-form"
        ref="form"
        v-model="isValid"
    >
        <v-text-field
            ref="email"
            v-model="contactForm.email"
            data-cy="clientEmail"
            label="Email"
        ></v-text-field>
        <div class="pl-3 mb-3 v-messages error--text" v-if="!editMode || emailExist">Leave blank if the YP has already signed up to TSU Live</div>
        <PhoneNumber v-model="contactForm.phone" />
        <h6 class="font-display text-left text-sm pl-2 text-grey-1">Please ensure that this is a valid mobile number in order to facilitate automated communications via SMS.</h6>
        <div class="relative">
            <div class="absolute font-bold text-grey-2 handle-text">@</div>
            <v-text-field
                class="social-input"
                v-model="contactForm.facebook"
                label="Facebook Handle"
            ></v-text-field>
        </div>
        <div class="relative">
            <div class="absolute font-bold text-grey-2 handle-text">@</div>
            <v-text-field
                class="social-input"
                v-model="contactForm.instagram"
                label="Instagram Handle"
            ></v-text-field>
        </div>
    </v-form>
</template>

<script>
import PhoneNumber from '@/components/shared/PhoneNumber'
import { FormRules } from '@/components/shared/mixins/formMixins'
export default {
    name: "Contact",
    components: { PhoneNumber },
    mixins: [ FormRules ],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isValid: true,
            defaultContact: {
                email: '',
                phone: {
                    code: 'AU',
                    phone: ''
                },
                facebook: '',
                instagram: ''
            },
            emailExist: false
        }
    },
    computed: {
        contactForm: {
            get() {
                return this.value || {...this.defaultContact}
            },
            set(contact) {
                this.$emit('input', contact)
            }
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        setEmailExists(exist) {
            this.emailExist = exist
        }
    }
}
</script>
