<template>
    <v-form
        class="a-contact-form"
        ref="form"
        v-model="isValid"
    >
        <div class="grid grid-cols-2 gap-x-4 w-full">
            <div class="col-span-1">
                <v-text-field
                    label="First name"
                    v-model="emergencyContactForm.firstName"
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    label="Last name"
                    v-model="emergencyContactForm.lastName"
                ></v-text-field>
            </div>
        </div>
        <v-text-field
            label="Relationship to young person"
            v-model="emergencyContactForm.relationship"
        ></v-text-field>
        <PhoneNumber
            ref="phone"
            v-model="emergencyContactForm.phone"
            :translations="translations"
            :required="phoneRequired"
        />
        <h6 class="font-display text-left text-sm pl-2 text-grey-1">Please ensure that this is a valid mobile number in order to facilitate automated communications via SMS.</h6>
        <v-text-field
            label="Email Contact"
            v-model="emergencyContactForm.email"
            :rules="emailRequired ? [required] : []"
        ></v-text-field>
    </v-form>
</template>

<script>
import { FormRules } from '@/components/shared/mixins/formMixins'
import PhoneNumber from '@/components/shared/PhoneNumber'

export default {
    name: 'EmergencyContact',
    components: { PhoneNumber },
    mixins: [ FormRules ],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            isValid: true,
            translations: {
                countrySelectorLabel: 'Country Code',
                countrySelectorError: null,
                phoneNumberLabel: 'Phone Contact',
                example: 'Example :'
            },
            defaultContact: {
                firstName: '',
                lastName: '',
                email: '',
                phone: {
                    code: 'AU',
                    phone: ''
                },
                relationship: ''
            }
        }
    },
    computed: {
        emergencyContactForm: {
            get() {
                return this.value || {...this.defaultContact}
            },
            set(emergencyContactForm) {
                this.$emit('input', emergencyContactForm)
            }
        },
        hasName() {
            return !!this.emergencyContactForm?.firstName || !!this.emergencyContactForm?.lastName
        },
        phoneRequired() {
            // phone number is required if an email hasn't been entered
            return this.hasName && !this.emergencyContactForm.email
        },
        emailRequired() {
            // email is required if the user hasn't entered a phone number
            return this.hasName && !this.emergencyContactForm?.phone?.phone
        }
    },
    methods: {
        validate() {
            const isFormValid = this.$refs.form.validate()
            const isPhoneValid = this.$refs.phone.validate()
            this.isValid = isFormValid && isPhoneValid

            return this.isValid
        },
    },
    watch: {
        emergencyContactForm: {
            deep: true,
            handler() {
                if (this.emergencyContactForm) this.$emit('input', this.emergencyContactForm)
            }
        }
    },
}
</script>
