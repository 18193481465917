import {format, addYears, endOfYear, startOfYear} from 'date-fns'

const DOBHelper = {
    data() {
        return {
            minAgeAllowed: 8,
            maxAgeAllowed: 60,
            defaultDobAge: 18
        }
    },
    computed: {
        minimumDob() {
            return format(startOfYear(addYears(new Date(), -this.maxAgeAllowed)), 'yyyy-MM-dd')
        },
        maximumDob() {
            return format(endOfYear(addYears(new Date(), -this.minAgeAllowed)), 'yyyy-MM-dd')
        },
        defaultDob() {
            return addYears(new Date(), -this.defaultDobAge)
        }
    }
}

export default DOBHelper
