<template>
    <v-form v-model="isValid" ref="form">
        <h4 class="font-display font-bold text-left text-lg">Basic Details</h4>
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-x-4 w-full">
            <div class="col-span-1">
                <v-text-field
                    ref="firstName"
                    v-model="form.firstName"
                    data-cy="clientFirstName"
                    label="First name"
                    :rules="[required]"
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    ref="lastName"
                    v-model="form.lastName"
                    data-cy="clientLastName"
                    label="Last name"
                    :rules="[required]"
                ></v-text-field>
            </div>
            <div class="col-span-1 relative aliases-select-wrapper">
                <v-combobox
                    attach=".aliases-select-wrapper"
                    v-model="form.aliases"
                    :items="[]"
                    small-chips
                    deletable-chips
                    clearable
                    label="Alias"
                    multiple
                    flat
                    :rules="[required]"
                ></v-combobox>
            </div>
            <div class="col-span-1 relative sex-select-wrapper">
                <v-select
                    attach=".sex-select-wrapper"
                    :items="genderOptions"
                    ref="day"
                    v-model="form.gender"
                    data-cy="clientGender"
                    label="Gender"
                    :rules="[required]"
                ></v-select>
            </div>
        </div>
        <div class="grid grid-cols-4 gap-x-4 w-full">
            <div class="col-span-1 relative dob-picker-wrapper">
                <EditableDatePicker
                    label="D.O.B"
                    ref="dob"
                    v-model="dob"
                    :rules="[required, validDob]"
                    :max="maximumDob"
                    :min="minimumDob"
                    :hint="ageHint"
                    :show-hint="true"
                    attach=".dob-picker-wrapper"
                />
            </div>
            <div class="col-span-1 relative day-select-wrapper">
                <v-select
                    attach=".day-select-wrapper"
                    :items="dobAccuracy"
                    ref="day"
                    v-model="form.day"
                    data-cy="clientDay"
                    label="Day"
                    :rules="[required]"
                ></v-select>
            </div>
            <div class="col-span-1 relative month-select-wrapper">
                <v-select
                    attach=".month-select-wrapper"
                    :items="dobAccuracy"
                    ref="month"
                    v-model="form.month"
                    data-cy="clientMonth"
                    label="Month"
                    :rules="[required]"
                ></v-select>
            </div>
            <div class="col-span-1 relative year-select-wrapper">
                <v-select
                    attach=".year-select-wrapper"
                    :items="dobAccuracy"
                    ref="year"
                    v-model="form.year"
                    data-cy="clientYear"
                    label="Year"
                    :rules="[required]"
                ></v-select>
            </div>
        </div>
    </v-form>
</template>

<script>
import {mapState} from 'vuex'
import abnValidator from '@/components/shared/mixins/abnValidator'
import Validators from '@/components/shared/mixins/validators'
import {parse, parseISO, getYear, setYear, isValid} from 'date-fns'
import {FormRules} from '@/components/shared/mixins/formMixins'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import DOBHelper from '@/components/shared/mixins/dobMixin'

export default {
    name: 'ClientForm',
    components: {EditableDatePicker},
    mixins: [abnValidator, Validators, FormRules, DOBHelper],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dob: '',
            isValid: true,
            maxYear: 50,
            alias: '',
            defaultForm: {
                firstName: '',
                lastName: '',
                aliases: [],
                gender: '',
                dob: '',
                day: '',
                month: '',
                year: ''
            }
        }
    },
    mounted() {
        this.dob = this.form.dob
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        reset() {
            this.dob = ''
            this.form.dob = ''
            return this.$refs.form.reset()
        },
        removeAlias(item) {
            this.form.aliases.splice(this.form.aliases.indexOf(item), 1)
            // this.form.aliases = [...this.form.aliases.slice(0, idx - 1), ...this.form.aliases.slice(idx)]
        },
        addAlias(e) {
            if (e.keyCode === 13) {
                this.form.aliases.push(this.alias)
            }
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        genderOptions() {
            return this.staticChoices['clientDetail.gender']
        },
        dobAccuracy() {
            return this.staticChoices['clientDetail.dobAccuracy']
        },
        form: {
            get() {
                return this.value || this.defaultForm
            },
            set(form) {
                this.$emit('input', form)
            }
        },
        age() {
            if (!this.dob) return ''
            let age = null
            const currentDate = new Date(),
                birthDate = parseISO(this.dob)
            const currentYear = getYear(currentDate)
            const birthdayYear = getYear(parseISO(this.dob))
            age = currentYear - birthdayYear
            // if the clients birthday is greater than today, then they haven't reached that age yet, so we minus 1 year
            if (setYear(birthDate, currentYear) > currentDate) {
                age -= 1
            }
            return age
        },
        ageHint() {
            return `Age: ${this.age}`
        }
    },
    watch: {
        dob() {
            this.form.dob = this.dob
            this.$emit('input', this.form.dob)
        },
        form() {
            this.dob = this.form.dob
        }
    },
}
</script>
