<template>
    <v-form class="a-contact-form" ref="form" v-model="isValid">
        <div class="grid grid-cols-2 gap-x-4 w-full">
            <div class="col-span-1 relative country-select-wrapper">
                <v-autocomplete
                    attach=".country-select-wrapper"
                    v-model="demographicsForm.countryOfBirth"
                    label="Country of Birth"
                    :items="countries"
                    :rules="[required]"
                ></v-autocomplete>
            </div>
            <div class="col-span-1 relative preferred-lang-select-wrapper">
                <v-autocomplete
                    attach=".preferred-lang-select-wrapper"
                    v-model="demographicsForm.preferredLanguage"
                    label="Preferred Language"
                    :items="languages"
                    :rules="[required]"
                ></v-autocomplete>
            </div>
        </div>
        <div class="relative other-lang-select-wrapper">
            <v-autocomplete
                attach=".other-lang-select-wrapper"
                v-model="demographicsForm.otherLanguages"
                label="Other Languages Spoken"
                :items="languages"
                small-chips
                deletable-chips
                clearable
                multiple
                flat
            ></v-autocomplete>
        </div>
        <div class="relative cultural-group-select-wrapper">
            <v-select
                attach=".cultural-group-select-wrapper"
                v-model="demographicsForm.culturalGroup"
                label="Cultural Group"
                :items="culturalGroups"
            ></v-select>
        </div>
        <div class="relative atsi-status-select-wrapper">
            <v-select
                attach=".atsi-status-select-wrapper"
                v-model="demographicsForm.atsiStatus"
                label="ATSI Status"
                :items="atsiStatuses"
                :rules="[required]"
            ></v-select>
        </div>
    </v-form>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules} from '@/components/shared/mixins/formMixins'
import camelcaseKeys from 'camelcase-keys'
import GET_COUNTRIES from '@/graphql/queries/getCountries.gql'
import GET_LANGUAGES from '@/graphql/queries/getLanguages.gql'
import GET_CULTURAL_GROUPS from '@/graphql/queries/getCulturalGroups.gql'

export default {
    name: 'Demographics',
    mixins: [FormRules],
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        countries: {
            query: GET_COUNTRIES,
            update({countries}) {
                countries = camelcaseKeys(countries, {deep: true})
                const cts = []
                const au = countries.find(country => country.value === this.countryCodes.AU)
                const nz = countries.find(country => country.value === this.countryCodes.NZ)
                if (au) cts.push(au)
                if (nz) cts.push(nz)
                return [...cts, ...countries]
            }
        },
        languages: {
            query: GET_LANGUAGES,
            update({languages}) {
                languages = camelcaseKeys(languages, {deep: true})
                const langs = []
                const en = languages.find(lang => lang.value === this.languageCodes.EN)
                if (en) langs.push(en)
                return [...langs, ...languages]
            }
        },
        culturalGroups: {
            query: GET_CULTURAL_GROUPS,
            update({culturalGroups}) {
                return camelcaseKeys(culturalGroups, {deep: true})
            }
        }
    },
    data() {
        return {
            isValid: true,
            languages: [],
            countries: [],
            culturalGroups: [],
            defaultDemographics: {
                countryOfBirth: '',
                preferredLanguage: '',
                otherLanguages: '',
                culturalGroup: '',
                AtsiStatus: ''
            },
            languageCodes: {
                EN: '1201'
            },
            countryCodes: {
                AU: '1101',
                NZ: '1201'
            }
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        atsiStatuses() {
            return this.staticChoices['user.atsiStatuses'] || []
        },
        demographicsForm: {
            get() {
                return this.value || this.defaultDemographics
            },
            set(demographicsForm) {
                this.$emit('input', demographicsForm)
            }
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        }
    },
    watch: {
        emergencyContactForm() {
            if (this.emergencyContactForm) this.$emit('input', this.emergencyContactForm)
        }
    }
}
</script>
